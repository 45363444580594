<template>
    <div>
        <vx-card
            title="Broker Servers"
            :subtitle="subTitle"
            class="no-over mb-base"
        >
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-table v-if="brokers" stripe :data="brokers" sort>
                        <template slot="thead">
                            <vs-th sortKey="company_name">Broker</vs-th>
                            <vs-th sortKey="active">Active</vs-th>
                            <vs-th sortKey="use_mylastwill_email_server"
                                >Server</vs-th
                            >
                            <vs-th sortKey="scheduled_tasks"
                                >Reminder Number</vs-th
                            >
                            <vs-th sortKey="scheduled_tasks"
                                >Reminder Templates</vs-th
                            >
                            <vs-th sortKey="scheduled_tasks"
                                >Reminder Send Time</vs-th
                            >
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr v-for="(broker, index) in data" :key="index">
                                <vs-td :data="data[index]"
                                    ><span
                                        class="link"
                                        v-on:click="
                                            $router.push({
                                                name: 'broker',
                                                params: {
                                                    pk: data[index].email,
                                                },
                                            })
                                        "
                                        >{{ data[index].company_name }}</span
                                    ></vs-td
                                >
                                <vs-td :data="data[index]">
                                    <vs-chip
                                        v-if="data[index].active"
                                        color="primary"
                                        >Active</vs-chip
                                    >
                                    <vs-chip v-else color="secondary"
                                        >Deactivated</vs-chip
                                    >
                                </vs-td>

                                <vs-td :data="data[index]">
                                    <vs-chip
                                        v-if="
                                            data[index]
                                                .use_mylastwill_email_server
                                        "
                                        color="primary"
                                        >MyLastWill
                                    </vs-chip>
                                    <div v-else>
                                        <vs-chip color="secondary">
                                            <vs-avatar
                                                v-if="
                                                    data[index]
                                                        .email_server_correct
                                                "
                                                icon="done"
                                            />
                                            <vs-avatar v-else icon="close" />
                                            Custom
                                        </vs-chip>
                                    </div>
                                </vs-td>
                                <vs-td :data="data[index]">
                                    <div
                                        v-for="(task, index) in data[index]
                                            .scheduled_tasks"
                                        :key="index"
                                    >
                                        <span v-if="task.reminder_number">{{
                                            task.reminder_number
                                        }}</span>
                                        <br />
                                    </div>
                                </vs-td>
                                <vs-td :data="data[index]">
                                    <div
                                        v-for="(task, index) in data[index]
                                            .scheduled_tasks"
                                        :key="index"
                                    >
                                        <span v-if="task.email_template"
                                            ><span class="font-bold"
                                                >Custom email :</span
                                            >
                                            {{
                                                task.email_template.description
                                            }}</span
                                        >
                                        <span v-if="task.text_template"
                                            ><span class="font-bold"
                                                >Custom sms :</span
                                            >
                                            {{
                                                task.email_template.description
                                            }}</span
                                        >
                                        <span v-if="task.site_email_template"
                                            ><span class="font-bold"
                                                >Site email :</span
                                            >{{
                                                task.site_email_template
                                                    .description
                                            }}</span
                                        >
                                        <span v-if="task.site_text_template"
                                            ><span class="font-bold"
                                                >Site sms :</span
                                            >
                                            {{
                                                task.site_text_template
                                                    .description
                                            }}</span
                                        >
                                        <br />
                                    </div>
                                </vs-td>
                                <vs-td :data="data[index]">
                                    <div
                                        v-for="(task, index) in data[index]
                                            .scheduled_tasks"
                                        :key="index"
                                    >
                                        <span v-if="task.send_time">{{
                                            task.send_time
                                        }}</span>
                                        <br />
                                    </div>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

export default {
    name: 'BrokerReportServerCard',
    mixins: [textFormat],
    components: {},
    mounted() {
        this.brokerReportServerFetch()
    },
    watch: {},
    computed: {
        subTitle() {
            if (this.brokers !== null) {
                var site = this.brokers.filter(
                    (broker) => broker.use_mylastwill_email_server
                )
                var custom = this.brokers.filter(
                    (broker) => !broker.use_mylastwill_email_server
                )
                return `${site.length} Brokers using site server, ${custom.length} using custom email server`
            }
            return 'No data'
        },
    },
    data() {
        return {
            brokers: null,
        }
    },
    methods: {
        brokerReportServerFetch() {
            http.get('report_broker_server')
                .then((response) => {
                    this.brokers = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

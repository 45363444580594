var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vx-card',{staticClass:"no-over mb-base",attrs:{"title":"Broker Servers","subtitle":_vm.subTitle}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[(_vm.brokers)?_c('vs-table',{attrs:{"stripe":"","data":_vm.brokers,"sort":""},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(broker,index){return _c('vs-tr',{key:index},[_c('vs-td',{attrs:{"data":data[index]}},[_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$router.push({
                                            name: 'broker',
                                            params: {
                                                pk: data[index].email,
                                            },
                                        })}}},[_vm._v(_vm._s(data[index].company_name))])]),_c('vs-td',{attrs:{"data":data[index]}},[(data[index].active)?_c('vs-chip',{attrs:{"color":"primary"}},[_vm._v("Active")]):_c('vs-chip',{attrs:{"color":"secondary"}},[_vm._v("Deactivated")])],1),_c('vs-td',{attrs:{"data":data[index]}},[(
                                        data[index]
                                            .use_mylastwill_email_server
                                    )?_c('vs-chip',{attrs:{"color":"primary"}},[_vm._v("MyLastWill ")]):_c('div',[_c('vs-chip',{attrs:{"color":"secondary"}},[(
                                                data[index]
                                                    .email_server_correct
                                            )?_c('vs-avatar',{attrs:{"icon":"done"}}):_c('vs-avatar',{attrs:{"icon":"close"}}),_vm._v(" Custom ")],1)],1)],1),_c('vs-td',{attrs:{"data":data[index]}},_vm._l((data[index]
                                        .scheduled_tasks),function(task,index){return _c('div',{key:index},[(task.reminder_number)?_c('span',[_vm._v(_vm._s(task.reminder_number))]):_vm._e(),_c('br')])}),0),_c('vs-td',{attrs:{"data":data[index]}},_vm._l((data[index]
                                        .scheduled_tasks),function(task,index){return _c('div',{key:index},[(task.email_template)?_c('span',[_c('span',{staticClass:"font-bold"},[_vm._v("Custom email :")]),_vm._v(" "+_vm._s(task.email_template.description))]):_vm._e(),(task.text_template)?_c('span',[_c('span',{staticClass:"font-bold"},[_vm._v("Custom sms :")]),_vm._v(" "+_vm._s(task.email_template.description))]):_vm._e(),(task.site_email_template)?_c('span',[_c('span',{staticClass:"font-bold"},[_vm._v("Site email :")]),_vm._v(_vm._s(task.site_email_template .description))]):_vm._e(),(task.site_text_template)?_c('span',[_c('span',{staticClass:"font-bold"},[_vm._v("Site sms :")]),_vm._v(" "+_vm._s(task.site_text_template .description))]):_vm._e(),_c('br')])}),0),_c('vs-td',{attrs:{"data":data[index]}},_vm._l((data[index]
                                        .scheduled_tasks),function(task,index){return _c('div',{key:index},[(task.send_time)?_c('span',[_vm._v(_vm._s(task.send_time))]):_vm._e(),_c('br')])}),0)],1)})}}],null,false,1700716296)},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sortKey":"company_name"}},[_vm._v("Broker")]),_c('vs-th',{attrs:{"sortKey":"active"}},[_vm._v("Active")]),_c('vs-th',{attrs:{"sortKey":"use_mylastwill_email_server"}},[_vm._v("Server")]),_c('vs-th',{attrs:{"sortKey":"scheduled_tasks"}},[_vm._v("Reminder Number")]),_c('vs-th',{attrs:{"sortKey":"scheduled_tasks"}},[_vm._v("Reminder Templates")]),_c('vs-th',{attrs:{"sortKey":"scheduled_tasks"}},[_vm._v("Reminder Send Time")])],1)],2):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div id="willOverview">
        <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <BrokerReportServerCard />
            </div>
        </div>
    </div>
</template>

<script>
import { http, httpGoCardless } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import BrokerReportServerCard from '../Cards/BrokerReportServer'

export default {
    name: 'ReportBrokerServer',
    mixins: [textFormat],
    components: {
        BrokerReportServerCard,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    beforeRouteLeave(to, from, next) {
        next()
    },
    data() {
        return {
            data: null,
            backPath: null,
        }
    },
}
</script>

<style scoped>
.no-over {
    overflow: visible;
}
</style>
